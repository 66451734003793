import React, { useEffect, useContext } from 'react';
import { SectionStyled } from './PageStyles.js';
import {Link} from 'react-router-dom';
import { postAnalytics } from "../../common/api";
import GlobalContext from '../../GlobalContext';
import i18n from '../../i18n.js';

function ExternalIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.1619 0.0999756H1.85272C1.00099 0.0999756 0.310547 0.790416 0.310547 1.64215V12.9514C0.310547 13.8031 1.00099 14.4935 1.85272 14.4935H13.1619C14.0137 14.4935 14.7041 13.8031 14.7041 12.9514V1.64215C14.7041 0.790416 14.0137 0.0999756 13.1619 0.0999756ZM12.9692 12.9514H2.04549C1.93903 12.9514 1.85272 12.8651 1.85272 12.7586V1.83491C1.85272 1.72845 1.93903 1.64214 2.04549 1.64214H12.9692C13.0756 1.64214 13.1619 1.72845 13.1619 1.83491V12.7586C13.1619 12.8651 13.0756 12.9514 12.9692 12.9514ZM11.2342 3.18431L6.86476 3.18897C6.65184 3.18897 6.47922 3.3616 6.47922 3.57451V4.46623C6.47922 4.6822 6.65663 4.85604 6.87257 4.85171L8.73605 4.78292L3.50782 10.0111C3.35727 10.1617 3.35727 10.4058 3.50782 10.5564L4.24774 11.2963C4.39829 11.4468 4.64241 11.4468 4.79296 11.2963L10.0212 6.06802L9.9524 7.93151C9.94803 8.14744 10.1219 8.32483 10.3379 8.32483H11.2296C11.4426 8.32483 11.6151 8.1522 11.6151 7.93929L11.6198 3.56985C11.6198 3.35694 11.4471 3.18431 11.2342 3.18431H11.2342Z" fill="#1175A7"/>
        </svg>
    )
}

export default function PageSection(props) {
    const { id, heading, subheading, linkText, showAll, children, link} = props;
    const { token } = useContext(GlobalContext);

    return (
        <SectionStyled heading={heading} subheading={subheading} linkText={linkText}>
            {heading && <div className="flex-container">
                <div className="flex-item headings">
                    <h2 className="section-heading" tabIndex="0">{heading}</h2>
                    {subheading && <h3 className="section-subheading" tabIndex="0" dangerouslySetInnerHTML={{ __html: `${subheading}` }}/>}
                </div>
                {linkText && 
                    <div className="flex-item section-link">
                        {link && link.includes('www') && 
                            <>
                                <a className="link" href={link} target="_blank" rel="noreferrer" tabIndex="0" aria-label={`Link. ${linkText} ${heading}. Link opens in a new tab.`}
                                    onClick={() => postAnalytics(token, {
                                        "event_name": props.analytic_name_prefix + "_view",
                                        "event_data": {
                                            "page_id": props.page_id,
                                            "type": "web",
                                            "url": link,
                                            "launch_type": "external"
                                        }
                                    })}>
                                    {linkText}
                                </a>
                                <ExternalIcon />
                            </>
                        }
                        {link && !link.includes('www') && 
                            <Link className="link" aria-label={`Link. ${linkText} ${heading}`} to={link}>{linkText}</Link>
                        }
                    </div>
                }
                {showAll && 
                    <div className="flex-item section-link">
                        <Link className="section-showAll" to={link}
                            aria-label={`Link. Show all ${heading}`}
                            onClick={() => postAnalytics(token, {
                                "event_name": "meditation_category_tap",
                                "event_data": {
                                    "id": id,
                                    "title": heading
                                }
                            })}>{i18n.t('show_all')}</Link>
                    </div>
                }
            </div>}
            <div className="section-content">
            {children}
            </div>
        </SectionStyled>
    );
}